/** @jsx jsx */
import React from "react";

import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { EpisodeConsumer } from "../components/context";

import SEO from "../components/seo";
import Header from "../components/header";
import Aside from "../components/aside";
import { SkipNavContent } from "@reach/skip-nav";

function EpisodeTemplate({ data: { episode, markdownRemark } }) {
  const image = markdownRemark && markdownRemark.frontmatter.image;
  const markdown = markdownRemark && markdownRemark;

  return (
    <React.Fragment>
      <EpisodeConsumer>
        {(context) => (
          <div>
            <SEO
              title={episode.title && episode.title}
              image={image && image.childImageSharp.original.src}
              description={episode.description && episode.description}
            />
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row"],
              }}
            >
              <SkipNavContent
                sx={{ maxWidth: ["100%", 710], minWidth: ["100%", 710] }}
                style={{
                  borderLeft: "2px solid",
                  borderRight: "2px solid",
                  borderColor: "#232B3B",
                }}
              >
                <Header
                  context={context}
                  episode={episode}
                  image={image}
                />
                <article>
                  <p>{episode.description && episode.description}</p>
                  {markdown && (
                    <div dangerouslySetInnerHTML={{ __html: markdown.html }} />
                  )}
                </article>
              </SkipNavContent>
              <Aside />
            </div>
          </div>
        )}
      </EpisodeConsumer>
    </React.Fragment>
  );
}

export default EpisodeTemplate;

export const episodeQuery = graphql`
  query ($id: String!) {
    episode(id: { eq: $id }) {
      id
      title
      description
      number
      published_at
      season_number
      fields {
        slug
      }
    }
    markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      frontmatter {
        id
        title
        image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
