import React from "react";

import Helmet from "react-helmet";

const Iubenda = () => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`var _iub = _iub || []; _iub.csConfiguration = {"siteId":2057083,"cookiePolicyId":62657355,"lang":"it"};`}
      </script>
      <script
        type="text/javascript"
        src="https://cs.iubenda.com/autoblocking/2057083.js"
      ></script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/gpp/stub.js"
      ></script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        charset="UTF-8"
        async
      ></script>
    </Helmet>
  );
};

export default Iubenda;
