/** @jsx jsx */
import { jsx } from "theme-ui";

import {
  FiFacebook,
  FiMail,
  FiInstagram,
  FiMessageCircle,
  FiMic,
  FiRss,
} from "react-icons/fi";

import { FaXTwitter } from "react-icons/fa6";

import config from "../lib/config";

import { css } from "@emotion/react";

import styled from "@emotion/styled";
import Link from "./link";

import ilaria from "../images/ilariaberetta.jpg";

import applePodcasts from "../images/apple-podcast.svg";
import spotifyPodcast from "../images/spotify-podcast.png";
import googlePodcasts from "../images/google-podcasts.svg";
import youtubeImage from "../images/yt_logo_rgb_dark.png";
import youtubePodcastsImage from "../images/ListenonYouTubeMusic-white-SVG.svg";

const PodcastProvider = styled(Link)(
  css({
    mb: 5,
    display: "flex",
    alignItems: "center",
    img: { m: 0, mr: 3 },
  })
);

function Aside() {
  return (
    <aside className="sidebar">
      <div
        className="lenotiziedellailly-providers"
        sx={{
          mb: 2,
          pr: [10, 0],
          a: { color: "text", textDecoration: "none" },
        }}
      >
        {false && (
          <iframe
            src="https://open.spotify.com/embed/show/6njkISCQLtT5QHG6qjnMLJ"
            title="Spotify Le notizie della illy"
            width="100%"
            height="232"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        )}
        {true && (
          <PodcastProvider
            target="_blank"
            to={config.youtubeUrl}
          >
            <img
              src={youtubeImage}
              alt="YouTube"
              width="100%"
            />
          </PodcastProvider>
        )}
        {config.applePodcastsUrl && (
          <PodcastProvider
            target="_blank"
            to={config.applePodcastsUrl}
          >
            <img
              src={applePodcasts}
              alt="Apple Podcasts"
              width="100%"
            />
          </PodcastProvider>
        )}
        {config.spotifyUrl && (
          <PodcastProvider
            target="_blank"
            to={config.spotifyUrl}
          >
            <img
              src={spotifyPodcast}
              alt="Spotify logo"
              width="100%"
            />
          </PodcastProvider>
        )}
        {true && (
          <PodcastProvider
            target="_blank"
            to={config.youtubePodcastsUrl}
          >
            <img
              src={youtubePodcastsImage}
              alt="YouTube"
              width="100%"
            />
          </PodcastProvider>
        )}
        {false && config.googlePodcastsUrl && (
          <PodcastProvider to={config.googlePodcastsUrl}>
            <img
              src={googlePodcasts}
              alt="Google Podcasts"
              width="100%"
            />
          </PodcastProvider>
        )}
      </div>
      <div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontSize: 14, fontStyle: "italic", marginBottom: 15 }}>
            L’attualità non è mai troppo grande per essere spiegata ai più
            piccoli
          </p>
        </div>
      </div>
      <div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h5>Autore</h5>
          <img
            className="lailly"
            src={ilaria}
            width="200px"
            alt="Ilaria Beretta"
            style={{ marginBottom: 5, borderRadius: 10 }}
          />
          <h4
            sx={{ mt: 3, mb: 1 }}
            style={{ marginBottom: 5 }}
          >
            Ilaria Beretta
          </h4>
          <p style={{ marginBottom: 5 }}>{"Giornalista"}</p>
          <p>
            <a
              style={{
                textDecoration: "none",
                background: "#ffc107",
                color: "#1A2232",
                padding: "4px 10px",
                fontWeight: "bold",
                borderRadius: 10,
                fontSize: 13,
              }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://ilariaberetta.com"
            >
              ilariaberetta.com
            </a>
          </p>
        </div>
      </div>
      <div>
        <h5>Contatti</h5>
        <ul className="lenotiziedellailly-contacts">
          <li>
            <FiMail />
            <a href="mailto:lenotiziedellailly@gmail.com">Email</a>
          </li>
          <li>
            <FiInstagram />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/notizieilly/"
            >
              Instagram
            </a>
          </li>
          <li>
            <FaXTwitter />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.x.com/notizieilly/"
            >
              X (Twitter)
            </a>
          </li>
          <li>
            <FiFacebook />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/notizieilly/"
            >
              Facebook
            </a>
          </li>
          <li>
            <FiMessageCircle />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/notizieilly"
            >
              Telegram
            </a>
          </li>
        </ul>
      </div>
      {true && (
        <div>
          <h5>Link</h5>
          <ul className="lenotiziedellailly-contacts">
            <li style={{ marginTop: 20 }}>
              <FiMic />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://podcasters.spotify.com/pod/show/notizieilly"
              >
                Spotify for Podcasters
              </a>
            </li>
            {false && (
              <li>
                <FiRss />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://anchor.fm/s/4d91ad3c/podcast/rss"
                >
                  Podcast RSS
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
      <div>
        <div
          className="lenotiziedellailly-footer-sidebar"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          style={{ borderTop: "1px solid rgba(255,255,255,0.7)" }}
        >
          <p
            style={{
              fontSize: 11,
              paddingTop: 16,
            }}
          >
            <span style={{ fontSize: 13 }}>
              Le notizie della illy è il podcast di{" "}
              <a
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://ilariaberetta.com"
              >
                Ilaria Beretta
              </a>
              <br />
              <br />
              sito web creato
              <br />
              da{" "}
              <a
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://matteomanzinello.com"
              >
                Matteo Manzinello
              </a>
            </span>
            <br />
            <br />
            musica da
            <br />
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fesliyanstudios.com/"
            >
              FesliyanStudios
            </a>
            <br />
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://freemusicarchive.org"
            >
              Free Music Archive
            </a>
            <br />
            foto e immagini, se non diversamente indicato, potrebbero essere
            state generate con l'intelligenza artificiale
            <br />
            <br />
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.iubenda.com/privacy-policy/62657355"
            >
              Privacy Policy
            </a>
            <br />
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.iubenda.com/privacy-policy/62657355/cookie-policy"
            >
              Cookie Policy
            </a>
            <br />
            <a
              style={{ color: "white" }}
              href="/open-source-credits"
            >
              Open Source Credits
            </a>
          </p>
        </div>
      </div>
    </aside>
  );
}

export default Aside;
