import React from "react";
import { useThemeUI, Box, Flex } from "theme-ui";
import Img from "gatsby-image";

import config from "../lib/config";

function Header({ context, episode, image }) {
  const themeContext = useThemeUI();
  const { theme } = themeContext;

  return (
    <Box
      className="header_main"
      sx={{
        backgroundImage: image
          ? "none"
          : `linear-gradient(224deg, ${theme.colors.primaryLighten50} 0%, ${theme.colors.primaryDarken} 100%)`,
      }}
    >
      {image && (
        <Img
          className="background-image"
          alt={episode.title}
          fluid={image.childImageSharp.fluid}
          sx={{ height: config.headerImageHeight }}
        />
      )}
      <Box className="header_content">
        <Flex
          sx={{
            height: "100%",
            width: "100%",
            alignItems: "flex-end",
            flexDirection: "row",
            pb: 8,
          }}
        >
          <Flex sx={{ width: "100%" }}>
            <div>
              <h1 style={{ fontSize: 36 }}>{episode.title}</h1>
              <h5>
                {false && <>LE NOTIZIE DELLA ILLY, </>}
                {episode.number !== 0 ? (
                  <>
                    STAGIONE {episode.season_number} - EPISODIO {episode.number}
                  </>
                ) : (
                  <>PRESENTAZIONE</>
                )}
              </h5>
            </div>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default Header;
